/**
 * 地图加载器
 */
import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";

/**
 * * 默认地图初始状态
 */
export const DEFAULT_MAP_STATE = {
  // 西安市
  latitude: 34.25249,
  longitude: 108.94142,
  zoom: 12,
  minZoom: 3,
};

/**
 * 地图初始化
 *
 */
export function map2DInit() {
  let map = new mapboxgl.Map({
    container: "map",
    center: [DEFAULT_MAP_STATE.longitude, DEFAULT_MAP_STATE.latitude],
    zoom: DEFAULT_MAP_STATE.zoom,
    minZoom: DEFAULT_MAP_STATE.minZoom,
  });
  //禁止地图旋转
  // map.dragRotate.disable();
  // map.touchZoomRotate.disableRotation();
  var language = new MapboxLanguage({
    defaultLanguage: "zh-Hans",
  });
  map.addControl(language);
  map._logoControl && map.removeControl(map._logoControl);
  return map;
}
