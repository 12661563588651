<template>
  <div class="about">
    <div class="back">
      <div id="map"></div>
      <div class="about_team">
        <div class="team_name">团队简介</div>
        <div class="team_text">
          终坚持从用户角度出发，始终坚持将GIS技术应用于社会服务的精神，致力为业界提供一个开放的软件环境。我们将
          凭借自身完善的GIS平台产业链和完备的行业应用解决能力，秉承“提供最卓越的GIS信息化服务”的核心理念，精于技
          术，务实创新，立足服务，追求卓越，力求在每一个项目中为客户提供优质而高效的系统解决方案，为GIS事业的
          发展做出自己的贡献。
        </div>
      </div>
    </div>
    <div class="honor">
      <div class="honor_title">荣誉资质</div>
      <div class="honor_content">
        <div class="honor_img"></div>
        <div class="honor_img"></div>
        <div class="honor_img"></div>
        <div class="honor_img"></div>
        <div class="honor_img"></div>
        <div class="honor_img"></div>
      </div>
    </div>
  </div>
</template>

<script>
let map = null;
import { map2DInit } from "@/util/map-utils";
export default {
  name: "About",
  data() {
    return {};
  },
  mounted() {
    // init map
    map = map2DInit();
    map.setStyle("mapbox://styles/mapbox/streets-v11");
  },
};
</script>

<style lang="scss" scoped>
.about {
  width: 100%;
  .back {
    width: 100%;
    position: relative;
    #map {
      width: 100%;
      height: 80vh;
      ::v-deep .mapboxgl-ctrl-bottom-right {
        display: none;
      }
    }
    .about_team {
      position: absolute;
      top: 85%;
      left: 15%;
      width: 70%;
      background: #ffffff;
      border-radius: 20px;
      box-shadow: 0px 25px 90px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5%;
      .team_name {
        font-size: 32px;
        font-family: Open Sans, Open Sans-Bold;
        font-weight: 700;
        text-align: LEFT;
        color: #333333;
        line-height: 32px;
        margin-bottom: 3%;
      }
      .team_text {
        font-size: 15.5px;
        font-family: Open Sans, Open Sans-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
      }
    }
  }
  .honor {
    margin-top: calc(30vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    .honor_title {
      font-size: 32px;
      font-family: Open Sans, Open Sans-Bold;
      font-weight: 700;
      text-align: LEFT;
      color: #333333;
      line-height: 42px;
    }
    .honor_content {
      margin: 5% 0;
      display: grid;
      grid-template-columns: repeat(3, 25vw);
      grid-template-rows: repeat(3);
      .honor_img {
        margin: 7%;
        height: calc(1.3 * 25vw);
        background: #e1dddc;
        border-radius: 20px;
        box-shadow: 0px 50px 80px 0px rgba(0, 0, 0, 0.05);
      }
    }
  }
}
</style>
